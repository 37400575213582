import {useState, useEffect, useRef, useCallback} from 'react';
import reactStringReplace from "react-string-replace";
import {useTeamContext} from "../team-context";
import {useMemberFromMemberId} from "../../../hooks/use-member-from-member-id";
import './member-details.css';
import {SectionHeader} from "../section-header";
import {SocialLink} from "./social-link";
import rallyAudio from './rally.mp3';
import drybones from './drybones.webp';
import getup from './getup.mp3';
import trainVideo from './train.webm';
import magic from './magic.mp3';


export const MemberDetails = ({playToasty}) => {
    const audioRef = useRef();
    const trainVideoRef = useRef();
    const drybonesRef = useRef();
    const [lastMember, setLastMember] = useState({});
    const {selectedMember} = useTeamContext();
    const member = useMemberFromMemberId(selectedMember);
    const {name, aliases, description, links, banner} = lastMember;
    useEffect(() => {
        if(member.name){
            setLastMember(member);
        }
    }, [member, setLastMember]);

    const realLinks = (links || []);
    const makeLinkGrid = realLinks.length > 4;

    const playRally = useCallback(() => {
        audioRef.current.src=rallyAudio;
        audioRef.current.play();
    }, [audioRef]);

    const showDrybones = useCallback(() => {
        drybonesRef.current.style.top = `${Math.round(Math.random() * 80)}%`;
        drybonesRef.current.style.left = `${Math.round(Math.random() * 80)}%`;
        drybonesRef.current.style.opacity = 1;
        setTimeout(() => {
            drybonesRef.current.style.opacity = 0;
        }, 3000);

    }, [drybonesRef]);

    const playGetup = useCallback(() => {
        audioRef.current.src=getup;
        audioRef.current.play();
    }, [audioRef]);

    const playTrainVideo = useCallback(() => {
        trainVideoRef.current.volume = 0.3;
        trainVideoRef.current.style.top = `${Math.round(Math.random() * 80)}%`;
        trainVideoRef.current.style.left = `${Math.round(Math.random() * 80)}%`;
        trainVideoRef.current.style.opacity = 1;
        trainVideoRef.current.play();
        setTimeout(() => {
            trainVideoRef.current.style.opacity = 0;
        }, 17000);
    }, []);


    const playMagic = useCallback(() => {
        audioRef.current.src=magic;
        audioRef.current.play();
    }, [audioRef]);

    let realDescription = reactStringReplace(description, 'good ol\' arcade days', (match) => {
       return (<span onClick={playToasty}>{match}</span>);
    });
    realDescription = reactStringReplace(realDescription, 'rallysport', (match) => {
        return (<span onClick={playRally}>{match}</span>);
    });
    realDescription = reactStringReplace(realDescription, 'their whims are never predicatble', (match) => {
        return (<span onClick={showDrybones}>{match}</span>);
    });
    realDescription = reactStringReplace(realDescription, 'multiplayer mayhem', (match) => {
        return (<span onClick={playGetup}>{match}</span>);
    });
    realDescription = reactStringReplace(realDescription, 'train sim', (match) => {
        return (<span onClick={playTrainVideo}>{match}</span>);
    });
    realDescription = reactStringReplace(realDescription, 'knack for abstract', (match) => {
        return (<a className='invisible-link' href='https://x.com/astrowell/status/1748997269251084534'>{match}</a>);
    });
    realDescription = reactStringReplace(realDescription, 'potion brewing', (match) => {
        return (<span onClick={playMagic}>{match}</span>);
    });



    return (
        <div className={`member-details ${!!selectedMember ? 'in' : ''}`}>
            <SectionHeader text="Who am I?" />
            <div className='member-details-content-wrapper'>
                <img className='member-details-background' src={banner} alt={`${name}'s banner`} />
                <div className='member-details-content'>
                    <div className='member-details-info-section'>
                        <div className='member-details-name'>
                            {name}
                        </div>
                        {
                            (aliases && aliases.length > 0) && (
                                <div className='member-details-aliases'>
                                    {`Aliases: ${aliases?.join(', ')}`}
                                </div>
                            )
                        }
                        <div className='member-details-description'>
                            {realDescription}
                        </div>
                    </div>
                    <div className={`member-details-link-section ${makeLinkGrid ? 'grid' : ''}`}>
                        {
                            realLinks.map(link => (
                                <SocialLink key={link.url} link={link} />
                            ))
                        }
                    </div>
                </div>
            </div>
            <img className='drybones-image' ref={drybonesRef} src={drybones} alt='bisexual skeleton' />
            <audio ref={audioRef} />
            <video className='train-video' ref={trainVideoRef} src={trainVideo}  />
        </div>
    );
};