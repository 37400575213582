
// eslint-disable-next-line
export default [
  {
    "filename": "2025-1-18.md",
    "title": "DataDump Podcast Continues!",
    "author": "SpadeTheSkeleton",
    "timestamp": 1737158400000
  },
  {
    "filename": "2025-1-10.md",
    "title": "Invalid Gateway Podcast?",
    "author": "AstrowellTV",
    "timestamp": 1736467200000
  },
  {
    "filename": "2024-8-11.md",
    "title": "★⋆ 𝙄𝙣𝙫𝙖𝙡𝙞𝙙 𝙍𝙚𝙩𝙧𝙤 𝘽𝙡𝙤𝙜 # 1 ⋆★",
    "author": "SpadeTheSkeleton",
    "timestamp": 1723334400000
  },
  {
    "filename": "2024-7-9.md",
    "title": "Festival of AGES Takes This Stage This Summer!",
    "author": "Invalid Gateway Team",
    "timestamp": 1720483200000
  },
  {
    "filename": "2024-6-26.md",
    "title": "Invalid Gateway X Gamers Outreach - Vtuber Summer Slam!",
    "author": "Invalid Gateway Team",
    "timestamp": 1719360000000
  }
]
